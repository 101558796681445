.rightbtn {
  float: right;
}

.pluginTitle {
  display: inline;
  text-transform: uppercase;
}
.versionTitle {
  display: inline;
}

.versionContainer {
  margin-top: 2rem !important;
  margin-bottom: 1.5rem !important;
}

.square {
  height: 3rem !important;
  width: 2rem !important;
  background-color: #f6f8fa !important;
  border: 1px solid lightgray;
}

.pluginIcon {
  width: inherit !important;
  height: inherit !important;
}
.pluginDetailsContainer {
  display: flex;
}

.pluginDetailsSubContainer {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  width: 60%;
}

.pluginName {
  font-weight: 400;
  color: rgb(52, 52, 61);
  font-size: 1.05rem;
}

.pluginSubDetails {
  color: #43425d;
  opacity: 0.9;
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.horizontalLine {
  border-top: 1px solid lightgray;
}

.containerTitles {
  color: #555;
  font-weight: 500;
}

.topIcon {
  height: 0.8rem !important;
  font-size: 1rem !important;
}
.marginBtn {
  margin: auto;
}
.no-version {
  margin-top: 1rem !important;
}


.versionTitlecard {
  color: #0057fa!important;
}