/* .modalZindex {
  z-index: 1301 !important;
} */
.inner{

}
.logstxt {
  color: white !important;
  white-space: pre-line !important;
}

.logs-container {
  max-height: 400px;
  width: "90%";
  overflow-x: auto;
  display: flex;
  flex-direction: column-reverse;
}

.logs-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #424242;
}

.logs-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.logs-container::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.cicdPre{
    white-space: pre-wrap;       
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap;    
    white-space: -o-pre-wrap;
    word-wrap: break-word;     
}