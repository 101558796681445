body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color : #f6f8fa
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--success{
  background : #4caf50 !important
}

.Toastify__toast-container{
  width : 410px !important
}

.pargraphMarginZero{
	margin : 0px !important;
}

.textPrimary {
  color: #0057fa !important;
}

.alignCenter{
	display: flex;
	justify-content: center;
}

.alignRight{
	display: flex;
	justify-content: flex-end;
}

.greyTitle{
  font-size: 0.8rem;
  color : #555;
  font-weight: 500;
}

.lightGreyTitle{
  font-size: 0.8rem;
  color : lightgray;
  font-weight: 500;
}

.displayFlexjustifySpacebetween{
  display: flex;
  justify-content: space-between;
}

.displayFlex{
  display: flex;
}

.oneRemLeftMarginSeperator{
  margin-left: 1rem !important;
}

.twoRemMarginTopAndoneRemBottomSeperator{
  margin-top:2rem !important;
  margin-bottom: 1rem !important;
}

.oneRemMarginTopAndoneRemBottomSeperator{
  margin-top:1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.padding1Rem{
  padding: 1rem !important;
}

.MuiStepIcon-root.MuiStepIcon-active{
  color : white !important;
  border : 1px solid #0057fa;
  border-radius: 50%;
}

.MuiStepIcon-text{
  fill: #0057fa !important;
}

.MuiStepLabel-label.MuiStepLabel-active{
  color : #0057fa !important;
}

.MuiStepIcon-root.MuiStepIcon-completed{
  color : #0057fa !important;
}

.card-Selected{
  background-color : #0057fa !important;
  color : white !important;
  padding : 30px !important;
}

.MuiStepIcon-root{
  color : white !important;
  border : 1px solid #B7B9CB;
  border-radius: 50%;
}

.MuiSelect-icon{
  color: grey !important;
}

.twoRemMarginBottomSeperator{
margin-bottom: 2rem !important;
}

.oneRemMarginBottomSeperator{
margin-bottom: 1rem !important;
}

.textDecorationNone{
  text-decoration: none;
}
.listContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom:20px;
  align-items: center;
}


.pendingstatus{
  margin-right: 8px;
  background-color: #f0ec0d;
  width: 10px;
  height: 10px;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  }

.activestatus{
  margin-right: 8px;
  background-color: #43a047;
  width: 10px;
  height: 10px;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  }
  
  .inactivestatus
  {
  margin-right: 8px;
  background-color: #e53935;
  width: 10px;
  height: 10px;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  }
  
  .dialogtitle{
    color:#444;
    font-size:18px!important;
    display: inline;
  }

  .right{
    float:right!important;
  }

  .m-t-10{
    margin-top:10px!important;
  }
  .m-t-20{
    margin-top:20px!important;
  }
  .m-b-5{
  margin-bottom:5px!important;
}
  .m-b-20{
    margin-bottom:20px!important;
  }
  .m-20{
    margin:20px;
  }
  .m-r-10{
      margin-right: 10px!important;;
  }
  .m-r-20{
    margin-right:20px!important;
  }
  .m-l-40{
    margin-left:40px!important;
  }

.w-100 {
  width: 100%;
}

.MuiDialog-paper {
  min-width: 360px;
}

.packagelistdiv{
  height: 250px;
  overflow-y: auto;
  overflow-x:hidden
}

.loader-center{
    align-content: center;
    text-align: center;
    margin: 1rem;
}

.center{
  text-align: center;
}

.podlogs{
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.arrowgodown{
  position: absolute;
  top:10px;
  right:10px;
}

.arrowgodownbtn{
  background-color: white!important;
}

.cnameLink {
  color: blue!important;
}

.openLinkIcon{
  font-size: 0.9rem!important;
  margin-left: 5px;
  vertical-align: middle;
}

.jobNav{
  vertical-align: top;
  margin-left:10px;
  font-weight: bold;
}

.clusterInfoStatus span{
  margin-left: 0!important;
}

.destroyed{
    background:#eeeeee!important;
}

.commitmsg{
  margin-top: 5px;
  font-size: 18px;
  color: #357dfd;
  margin-bottom: 5px;
  font-weight: 500;
}

.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.header-details-grid {
  display: flex;
  align-items: center;
}

.infoGrid{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.sucessIcon{
  color:#53b43f;
  height:1.5em;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #0e42a3;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 250px;
  padding: 5px 0;
  background-color: #0057fa;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-align: center;
  font-size:10px;
  text-transform: none;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 38px;
}
.ribbon-top-right::after {
  bottom: 38px;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.buttonStyle {
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: underline;
  cursor: pointer;
  color: blue !important;
}

.buttonStyle:hover{
  color:#0e42a3
}

.titleStyle {
  color: 'blue'
}

.titleStyle :hover {
  color: #0e42a3;
  cursor: pointer;
}

.orgDisplay {
  display: flex;
  flex-direction: row;
}

.org-m-4 {
  padding: 4px;
  margin-top: 4px;
}