.newProjectTitle{
	color: white;
	text-decoration: none;
	font-size: 12px
}

.newProjectContainer{
	display: flex;
	justify-content: center;
}

.MuiDrawer-paperAnchorDockedLeft{
	border-right: 0px !important;
}

.linkText{
	color : 'white'
}